import styled, { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import { motion } from "framer-motion"
import { IoMdClose } from "react-icons/io"
import { CursorType } from "../constants"

const FLEX = {
  end: "flex-end",
  start: "flex-start",
  center: "center",
  between: "space-between",
  around: "space-around",
}

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
      text-decoration: none;
      /* cursor: none; */
      outline: none;
  }

  html {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      font-size: 16px;
  }
  body {
      font-family: 'Work Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      background: ${({ theme: { background } }) => background};
      color: ${({ theme: { text } }) => text};
      overflow-x: hidden;
      transition: all 500ms ease-in-out;
      transition-property: background, color;
  }
  html, body {
    overflow-x: hidden;
  }

  span, div, a {
    outline: none;
  }

  div {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6, ul, li, a, p, ol {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  h1 {
    font-weight: 700;
    font-size: 4.25rem;
  }
  h2 {
    font-weight: 700;
    font-size: 2rem;
  }
  p {
    font-weight: 400;
    font-size: 1rem;
  }

  .p2 {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 30px;
  }
  label {
    font-weight: 700;
    font-size: 1.5rem;
  }
  button {
    font-weight: 500;
    font-size: 1.25rem;
  }

  .nav-open {
    .nav-container {
      opacity: 1;
      visibility: visible;
    }
    .header-container {
      background: transparent;
      -webkit-backdrop-filter: blur(0px);
      backdrop-filter: blur(0px);
    }
    .cursor-container {
      background: #FFFFFF;
      &.${CursorType.HOVER} {
        background: transparent;
        border-color: #FFFFFF; 
      }
    }
    .ham-container {
      span {
        background: #FFFFFF;
      }
    }
  }

  .ReactModal__Content {
    border: none !important;
    background: transparent !important;
  }
  .video {
    margin-top: 20px;
  }

  .ReactModal__Body--open {
    header {
      display: none !important;
    }
  }

  .videoPlayContainer {
    display: flex;
    flex-direction: column;

    a {
      align-self: flex-end;
    }
  }

  @media (max-width: 599px) {
    * {
      text-decoration: none;
      cursor: auto;
    }
    html, body {
      font-size: 13px;
    }
    h1 {
      font-weight: 700;
      font-size: 2.85rem;
    }
  }
  @media (min-width: 600px) {
    html, body {
      font-size: 14px;
    }
    h1 {
      font-weight: 700;
      font-size: 3rem;
    }
  }
  @media (min-width: 900px) {
    html, body {
      font-size: 15px;
    }
    h1 {
      font-weight: 700;
      font-size: 4rem;
    }
  }
  @media (min-width: 1200px) {
    html, body {
      font-size: 16px;
    }
    h1 {
      font-weight: 700;
      font-size: 4.25rem;
    }
  }

`

export const PageAnimContainer = styled(motion.div).attrs({
  initial: { opacity: 0, y: 100 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0 },
  transition: { ease: "easeOut", duration: 1 },
})``

export const Cursor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background: ${({ theme: { cursor } }) => cursor};
  opacity: 0.7;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  /* transition: all 0.3s ease-in-out; */
  /* transition-property: width, height, border; */
  will-change: width, height, border, transform;
  pointer-events: none;
  z-index: 1999;
  /* transition: all 200ms linear; */

  &.${CursorType.HOVER} {
    height: 40px;
    width: 40px;
    opacity: 1;
    background: transparent;
    border: 3px solid ${({ theme: { cursor } }) => cursor};
  }

  &.${CursorType.BIG} {
    height: 80px;
    width: 80px;
    opacity: 0.5;
  }

  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) {
    display: none;
  }
  @media (min-width: 900px) {
    display: block;
  }
`

export const PageContainer = styled.section`
  padding-top: 90px;
`
export const CloseIcon = styled(IoMdClose)`
  /* font-size: 1.5rem; */
  width: 35px;
  height: 30px;
  color: ${({ theme: { primary } }) => primary};

  /* @media (max-width: 599px) {
    font-size: 2.5rem;
  } */
`

export const Container = styled.div`
  flex-grow: 1;
  margin: 0 auto;
  padding: 0 32px;
  width: 100%;
  height: 100%;

  @media (max-width: 599px) {
    padding: 0 20px;
    &.footerContainer {
      padding: 0;
    }
  }
  @media (min-width: 600px) {
    padding: 0 35px;
  }
  @media (min-width: 900px) {
    padding: 0 50px;
  }
  @media (min-width: 1200px) {
    padding: 0 72px;
  }
`

export const Flex = styled.div`
  display: flex;
  flex-wrap: ${({ wrap = "nowrap" }) => wrap};
  flex-direction: ${({ direction = "row" }) => direction};
  align-items: ${({ align = "auto" }) => FLEX[align]};
  justify-content: ${({ justify = "auto" }) => FLEX[justify]};
  height: ${({ height = "100%" }) => height};
`

export const Button = styled(motion.button).attrs({
  whileHover: {
    scale: 1.1,
  },
  whileTap: { scale: 0.9 },
})`
  height: 3.75rem;
  background: ${({ theme: { primary } }) => primary};
  border: 0;
  box-shadow: none;
  border-radius: 8px;
  padding: 0 2.5rem;
  color: #ffffff;
  outline: none;
  font-size: 1.25rem;
`
